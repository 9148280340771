import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import defiBanner from "assets/images/defiBanner.png";
import markIcon from "assets/images/markIcon.png";
import usdtImg from "assets/images/usdtImg.png";
import bitIcon from "assets/images/bitIcon.png";
import bitIcon1 from "assets/images/bitIcon1.png";
import bitIcon2 from "assets/images/bitIcon2.png";
import onePolyline from "assets/images/onePolyline.png";
import rightWihite from "assets/images/rightWihite.png";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  let location = useLocation();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const [defiPage, setDefiPage] = useState<any>({});

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const onClickLink = (v: any) => {
    if (v.status === "1") {
      goToPage(`/defiPay/${v.id}`);
    }
  };
  // /api/index/defiPage

  const getDefiPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: "POST",
        url: "/api/index/defiPage",
        params: { lang: i18n.language },
        data: parmas,
      });
      setDefiPage(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getDefiPage();
  }, [i18n.language]);

  return (
    <>
      <div className="Defi">
        <Header title={t("託管")} />

        <div className="Defi__greenBox">
          <div className="Defi__imgBox flex__space__between">
            <div className="imgBox__left">
              <div>{t("參與鏈上火爆項目")}</div>
              <div className="imgBox__left__bold">{t("賺取理財超額收益")}</div>
              <div className="imgBox__left__btn">{t("立即參與")}</div>
            </div>
            <Image src={defiBanner} width={130} height={96} />
          </div>
          <div className="flex__space__between">
            <div className="left__box">
              <div className="bgGreenAsh__box flex__direction">
                <div className="paddingt12 text__center mb4">
                  {t("託管資產總計")}
                </div>
                <div className="Defi__num text__center font18">
                  ${defiPage?.defiamount}
                </div>
                <div
                  onClick={() =>
                    goToPage("/defiIncome", {
                      state: "1",
                    })
                  }
                  className="flex__space__center text__center"
                >
                  <span>{t("訂單詳情")}</span>
                  <Image
                    className="imgClass"
                    src={rightWihite}
                    width={6}
                    height={6}
                  />
                </div>
              </div>
            </div>
            <div className="left__box">
              <div className="bgGreenAsh__box flex__direction">
                <div className="paddingt12 text__center mb4">
                  {t("累計收益")}
                </div>
                <div className="Defi__num text__center font18">
                  +${defiPage?.income}
                </div>
                <div
                  onClick={() =>
                    goToPage("/defiIncome", {
                      state: "0",
                    })
                  }
                  className="flex__space__center text__center"
                >
                  <span>{t("收益明細")}</span>
                  <Image
                    className="imgClass"
                    src={rightWihite}
                    width={6}
                    height={6}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Defi__bottom">
          <div className="bot__title">{t("託管理財推薦")}</div>
          {defiPage?.defiproductsdata?.length > 0 &&
            defiPage?.defiproductsdata.map((item: any, index: any) => {
              return (
                <div key={index} className="card__box paddingNoT20">
                  <div className="flex__space__between">
                    <div className="card__title">
                      <span>{t("預計挖礦時間")}:</span> {item.starttime}
                    </div>
                  </div>
                  <div className="Defi__box flex__space__between">
                    <div className="Defi__box__Polyline flex__direction">
                      <div className="flex__space__between">
                        <div className="Defi__box__Polyline__Img">
                          <Image
                            className="Defi__box__Polyline__Img0"
                            src={usdtImg}
                            height={32}
                            width={32}
                          />
                          <Image
                            className="Defi__box__Polyline__Img1"
                            src={bitIcon}
                            height={20}
                            width={20}
                          />
                          <Image
                            className="Defi__box__Polyline__Img2"
                            src={bitIcon1}
                            height={20}
                            width={20}
                          />
                          <Image
                            className="Defi__box__Polyline__Img3"
                            src={bitIcon2}
                            height={20}
                            width={20}
                          />
                        </div>
                        <div className="flex__direction">
                          <span className="Defi__box__title text__over">
                            {item.name}
                          </span>
                          <span className="Defi__box__quota text__over">
                            {t('限購')}{item.quota}
                          </span>
                        </div>
                      </div>
                      <div>
                        <Image src={onePolyline} height={41} width={160} />{" "}
                      </div>
                    </div>
                    <div className="rightBox flex__direction">
                      <div className="Defi__APY flex__space__end">
                      <Image
                            className=""
                            src={usdtImg}
                            height={32}
                            width={32}
                          />
                          {/* {t("金額")}： */}
                        <span className="Defi__APY__key text__right ml8">
                          {item.status === "0" ? "???" : item.price}
                        </span>{" "}
                      </div>
                      <div className="Defi__APY font__blod">
                        {/* 托管周期和返利利率 */}
                        <span className="Defi__TVL text__right">{t('託管週期')}：</span>
                        <span className="Defi__TVL text__right">
                          {item.status === "0" ? "???" : item.cycle + t('日')}
                        </span>{" "}
                      </div>
                      <div className="Defi__APY font__blod">
                        <span className="Defi__TVL text__right">{t('回饋利率')}：</span>
                        <span className="Defi__TVL text__right">
                          {item.status === "0" ? "???" : item.interestrate}
                        </span>{" "}
                      </div>
                      <div className="Defi__btnBox">
                        <div
                          onClick={() => onClickLink(item)}
                          className="Defi__btn"
                        >
                          {item.status === "0"
                            ? t("敬請期待")
                            : item.status === "1"
                            ? t("立即購買")
                            : t("已售罄")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Outlet></Outlet>
      </div>
    </>
  );
};
export default Index;
