import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import cloudBanner from "assets/images/cloudBanner.png";
import rightWihite from "assets/images/rightWihite.png";
import monitorImg from "assets/images/monitorImg.png";
import BTCIcon from "assets/images/BTCIcon.png";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [isIcome, setIsIcome] = useState<any>(false);
  const [powerPage, setPowerPage] = useState<any>({});
  let location = useLocation();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const goToPage = useNavitateLink();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  //  /api/index/powerPage

  const getPowerPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token,  };
      const res = await request({
        method: "POST",
        url: "/api/index/powerPage",
        params: {lang: i18n.language},
        data: parmas,
      });
      setPowerPage(res.data);

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  useEffect(() => {
    const nowUrl = location.pathname;
    setIsIcome(nowUrl === "/cloudComputed");
  }, [location]);

  useEffect(() => {
    getPowerPage();
  }, [i18n.language]);

  return (
    <>
      <div className="CloudComputed">
        {isIcome && (
          <>
            <Header title={t('算力租賃')} />

            <div className="CloudComputed__greenBox">
              <div className="CloudComputed__imgBox flex__space__between">
                <div className="imgBox__left">
                  <div>{t('一站式便捷算力租賃')}</div>
                  <div className="imgBox__left__bold">{t('獲取豐厚挖礦收益')}</div>
                  <div className="imgBox__left__btn">{t('立即參與')}</div>
                </div>
                <Image src={cloudBanner} width={106} height={114} />
              </div>
                <div className="left__box flex__space__between">
                  <div className="bgGreenAsh__box">
                    <div className="marginBottom text__center">{t('算力租賃')}</div>
                    <div className="CloudComputed__num text__center">
                      {powerPage?.power}USDT
                    </div>
                    <div
                      onClick={() =>
                        goToPage("/cloudComputed/income", {
                          state: "1",
                        })
                      }
                      className="flex__space__center text__center"
                    >
                      <span>{t('訂單詳情')}</span>
                      <Image
                        className="imgClass"
                        src={rightWihite}
                        width={6}
                        height={6}
                      />
                    </div>
                  </div>
                  <div className="bgGreenAsh__box">
                    <div className="marginBottom text__center">{t('累計產出')}</div>
                    <div className="CloudComputed__num text__center">
                      {powerPage?.income}USDT
                    </div>
                    <div
                      onClick={() =>
                        goToPage("/cloudComputed/income", {
                          state: "0",
                        })
                      }
                      className="flex__space__center text__center"
                    >
                      <span>{t('收益明細')}</span>
                      <Image
                        className="imgClass"
                        src={rightWihite}
                        width={6}
                        height={6}
                      />
                    </div>
                  </div>
                </div>
            </div>
            <div className="CloudComputed__bottom">
              <div className="bot__title">{t('算力產品推薦')}</div>
              {powerPage?.products?.length > 0 &&
                powerPage?.products.map((item: any, index: number) => {
                  return (
                    <div key={index} className="card__box">
                      <div className="card__title flex__space__between">
                        {/* <div>預計挖礦時間：{"03/06-09/02"}</div> */}
                        <div>
                          {t('挖礦天數')}: <span className="font__bold">{item.cycle}{t('日')}</span>
                        </div>
                      </div>
                      <div className="card__content">
                        <div className="card__BTC font__bold">
                          <Image src={BTCIcon} width={40} height={40} />
                          <span>BTC</span>
                        </div>
                        <div className="content__list mt12 flex__space__between">
                          <div>
                            <div className="title__font">{t('算力售價')}</div>
                            <div className="font__bold">{item.powerprice}USDT/T</div>
                            <div className="title__font">{t('預估算力收益')}</div>
                            <div className="font__bold">{item.powerincome}USDT/T/D</div>
                          </div>
                          <div className="text__right">
                            <div className="title__font">{t('到期礦機折損')}</div>
                            <div className="font__bold"><span className="yellow-color">{item.deadlinestatus === '0' ? t('限免') : ''}</span> {item.deadlinestatus === '0' ? '' : item.damagerate}</div>
                            <div className="title__font">{t('預估日化收益率')}</div>
                            <div className="font__bold green2-color">{item.dayrate}</div>
                          </div>
                        </div>
                        <div className="content__btn flex__space__between font__bold">
                          <div>
                            {t('起購價')}:{" "}
                            <span className="yellow-color">{item.minprice}USDT</span>
                          </div>
                          <div
                            onClick={() => goToPage(`/cloudComputed/lease/${item.id}`)}
                            className="nowBtn"
                          >
                            {t('立即租賃')}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
        <Outlet></Outlet>
      </div>
    </>
  );
};
export default Index;
