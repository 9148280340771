import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getBalance_bnb, getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, modalComponents } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import manageMoneyBanner from "assets/images/manageMoneyBanner.png";
import markIcon from "assets/images/markIcon.png";
import usdtImg from "assets/images/usdtImg.png";
import rightWihite from "assets/images/rightWihite.png";
import bitIcon from "assets/images/bitIcon.png";
import bitIcon1 from "assets/images/bitIcon1.png";
import bitIcon2 from "assets/images/bitIcon2.png";
import onePolyline from "assets/images/onePolyline.png";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import { throttle } from "lodash";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  const [form] = Form.useForm()
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate, library, chainId, connector } =
    useWeb3React();
  const [aiPage, setAiPage] = useState<any>();
  const [usdt, setUsdt] = useState<any>(form.getFieldValue('amount'));
  const [btnLoadin, setBtnLoadin] = useState<any>(false);
  const [detail, setDetail] = useState<any>([]);
  const [aiDetaliPage, setAiDetaliPage] = useState<any>();
  let location = useLocation();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

  const modalContent = (
    <>
      <div>
        <div className="text__center mb16">{t('系統錢包資金不足，是否前往儲值')}</div>
        <div
          onClick={() => {
            Modal.clear();
            goToPage("/deposit");
          }}
          className="modal__goDeposit"
        >
          {t('確定')}
        </div>
      </div>
    </>
  );



  const getAiPage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/aiPage",
        params: {lang: i18n.language},
        data: parmas,
      });
      setAiPage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };

  const onFinish = async(v: any) => {
    try {
      if (Number(aiPage?.amount || 0) < Number(v.amount || 0)) {
        Modal.show({
          title: t('提示'),
          content: modalContent,
          closeOnMaskClick: true,
        });
        return;
      }
      if (v.amount <= 0) {
        Toast.show(t('輸入數量不得小於0'));
        return;
      }
      const parmas = { account: email || address,
        token: token, amount: v.amount};
      const res: any = await request({
        method: "POST",
        url: "/api/users/sellai",
        params: {lang: i18n.language},
        data: parmas,
      });
      Toast.show(res.msg)
      getAiPage();
      // setInvitePage(res.data)
      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  } 


  const ThrottledOnClickNodeBuy  = throttle(onFinish, 3000, { leading: true, trailing: false });

  useEffect(() => {
    getAiPage();
  }, [i18n.language]);
  useEffect(() => {
    form.setFieldValue('amount', 0)
  }, []);

  return (
    <>
      <div className="ManageMoney">
        <Header title={t('AI複合交易')} />

        <div className="ManageMoney__greenBox">
          <div className="ManageMoney__imgBox">
            <Image src={manageMoneyBanner} height={140} />
          </div>
          <div className="flex__space__between">
          <div className="bgGreenAsh__box">
                    <div className="marginBottom text__center">{t('理財資產總計')}</div>
                    <div className="CloudComputed__num text__center">{aiPage?.amount}U</div>
                    <div onClick={() => goToPage('/manageDetail')} className="flex__space__center text__center">
                      <span>{t('查看詳情')}</span>
                      <Image
                        className="imgClass"
                        src={rightWihite}
                        width={6}
                        height={6}
                      />
                    </div>
                  </div>
            <div className="monitor__box aling__center">
              <div style={{margin: 'auto'}}><div>{t('累計收益')}</div>
              <div className="mb8 font__bold">+${aiPage?.income}</div></div>
            </div>
          </div>
        </div>
        <div className="ManageMoney__bottom">
          <div className="bot__title">{t('理財產品列表')}</div>
          <div className="card__box">
              <div className="AI__box">
                <Image src={usdtImg} width={32} height={32} />{" "}
                <span className="AI__title">USDT {t('複合套利')}</span>{" "}
                <Image onClick={() => modalComponents(t("產品說明"),t("複合套利策略包含跨平臺價差回歸套利、跨期合約交割套利、期現價差套利和資金費率套利等多個部分，利用同交易對不同交易平臺之間的敞口機會、交割合約的跨期限交易/期現盤口價差波動和費率體系等系統性機會進行安全套利，不受大盤漲跌影響。 為用戶帶來穩定的理財收益。"))} src={markIcon} width={20} height={20} />
              </div>
              <div className="AI__key">
                <div className="AI__key__name">
                  <span>{t('日化報酬率')}</span>
                  <Image onClick={() => modalComponents(t("收益規則"),t("殖利率根據市場行情會有所波動不作為實際收益保障"))} src={markIcon} width={20} height={20} />{" "}
                </div>
                <div className="AI__key__value">{aiPage?.dayrate}</div>
              </div>
              <div className="AI__key">
                <div className="AI__key__name">
                  <span>{t('期限')}</span>
                </div>
                <div className="AI__key__value">{t('活期')}</div>
              </div>
              <div className="AI__key">
                <div className="AI__key__name">
                  <span>{t('生息時間')}</span>
                  <Image onClick={() => modalComponents(t("生息規則"),t("算力板塊和節點板塊每天的盈利自動進入AI復合產品，隨時提現，日化率1.5%"))} src={markIcon} width={20} height={20} />{" "}
                </div>
                <div className="AI__key__value">T+1</div>
              </div>
              <div className="font__bold mb8 mt16">
                {t('金額數量')}
              </div>
        <div className="">
        <Form
          form={form}
          onFinish={ThrottledOnClickNodeBuy}
          footer={
            <>
              <Button
                loading={btnLoadin}
                style={{
                  "--background-color": "rgb(36, 105, 82)",
                  "--border-radius": "40px",
                  "--text-color": "#fff",
                }}
                block
                type="submit"
                size="large"
              >
                {t('提取')}
              </Button>
            </>
          }
          layout="horizontal"
        >
          <Form.Item
            name={"amount"}
            extra={
              <div className={"form__USDT"}>
                {" "}
                <span>USDT</span>{" "}
                <span
                  onClick={() => {
                    form.setFieldValue("amount", aiPage?.amount);
                  }}
                  className="allWithdraw"
                >
                  Max
                </span>
              </div>
            }
          >
            <Input />
          </Form.Item>
        </Form>
        </div>
              {/* <Form
                form={form}
                onFinish={ThrottledOnClickNodeBuy}
                onFieldsChange={(a) => {setUsdt(a?.[0]?.value)} }
                footer={
                  <>
                   <Button
                    style={{
                      '--background-color': 'rgb(36, 105, 82)',
                      '--border-radius': '40px',
                      '--text-color': '#fff'
                    }}
                  block type='submit' size='large'>
                    提取
                  </Button>
                  </>
                }
                layout="horizontal">
                <Form.Item name={'amount'} extra={<div className={"form__USDT"}> <span>USDT</span> <span onClick={() => {form.setFieldValue('amount', balance); setUsdt(balance)}} className="allWithdraw">Max</span></div>}>
                  <Input />
                </Form.Item>
                <div className="title__font">金額數量：{usdt || 0}USDT</div>
              </Form> */}
            </div>
        </div>
        <Outlet></Outlet>
      </div>
    </>
  );
};
export default Index;
