import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import topup from "assets/images/topup.png"
import withdraw from "assets/images/withdraw.png"
import exchange from "assets/images/exchange.png"
import recordIcon from "assets/images/recordIcon.png"
import rightBlack from "assets/images/rightBlack.png"
import usdtImg from "assets/images/usdtImg.png"
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");
  // @ts-ignore
  const navigate = useNavigate();

  const [ walletPage, setWalletPage] = useState<any>();
  const [ funddetailPage, setFunddetailPage] = useState<any>();
  const [btnLoadin, setBtnLoadin] = useState<any>(false);


   const getWalletPage =async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: 'POST',
        url: '/api/index/walletPage',
        params: {lang: i18n.language},
        data: parmas
      })
      setWalletPage(res.data);
    } catch (error) {

    }
  }
   const getFunddetailPage =async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: 'POST',
        url: '/api/index/funddetailPage',
        params: {lang: i18n.language},
        data: parmas
      })
      setFunddetailPage(res.data);
    } catch (error) {

    }
  }

  const modalContent = (
    <>
      <div>
        <div
          onClick={() => {
            Modal.clear();
          }}
          className="modal__ok"
        >
          {t('確定')}
        </div>
      </div>
    </>
  );

  const onClickBtnopenpower = async (value: any) => {
    try {
      const parmas = { account: email || address,
        token: token,  orderid: value.id };
      const res = await request({
        method: "POST",
        url: "/api/users/sellpower",
        params: { lang: i18n.language },
        data: parmas,
      });
    }catch (error) {
      console.log('openpower: ',error)
    }
  }

  const onClickBtn = async (value: any) => {
    try {
      setBtnLoadin(true);
      if (value.status === "2") {
        Modal.show({
          title: t('訂單結束前三天可以關閉'),
          content: modalContent,
          closeOnMaskClick: true,
        });
        setBtnLoadin(false);
        return
      }
      if (value.status === "4") {
        Modal.show({
          title: t('已關閉複投'),
          content:  (
              <>
                <div>
                  <div
                      onClick={() => {
                        onClickBtnopenpower(value)
                        Modal.clear();
                      }}
                      className="modal__ok"
                  >
                    {t('開啟複投')}
                  </div>
                </div>
              </>
          ),
          closeOnMaskClick: true,
        });
        setBtnLoadin(false);
        return
      }
      const parmas = { account: email || address,
        token: token,  orderid: value.id };
      const res = await request({
        method: "POST",
        url: "/api/users/sellpower",
        params: { lang: i18n.language },
        data: parmas,
      });
      setBtnLoadin(false);
      getWalletPage();
      getFunddetailPage();
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {setBtnLoadin(false);}
  };

  useEffect(() => {
    getWalletPage();
    getFunddetailPage();
  }, []);

  return (
    <>
      <div className="WalletNet">
        <Header title={t('錢包')} />
        <div className="WalletNet__top">
          <div className="WalletNet__top__card">
            <div className="card__title">{t('賬戶餘額')}</div>
            <div className="card__blance">{walletPage?.balance} <span> USDT</span></div>
            <div onClick={() => goToPage('/manageMoney')}  className="flex__space__between">
              <div className="card__icome aling__center">{t('可提取收益')} <Image className="ml8" src={rightBlack} width={6} /></div>
              <div className="card__icome__blance "><span>$ </span> {walletPage?.income}</div>
            </div>
            <div className="bannerActionBox">
              <div className="bannerAction">
                <div onClick={() => goToPage('/deposit')}  className="bannerAction__btn"><Image src={topup} width={24} height={24}/> <span>{t('儲值')}</span> </div>
                <div onClick={() => goToPage('/withdraw')} className="bannerAction__btn"><Image src={withdraw} width={24} height={24}/> <span>{t('提現')}</span> </div>
                <div onClick={() => goToPage('/actionRecord/2')} className="bannerAction__btn"><Image src={exchange} width={24} height={24}/> <span>{t('兌換')}</span> </div>
                <div onClick={() => goToPage('/actionRecord/0')} className="bannerAction__btn"><Image src={recordIcon} width={24} height={24}/> <span>{t('記錄')}</span> </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{overflowX: 'scroll'}} className="WalletNet__bot">
        <div className="title">{t('訂單明細')}</div>
          <table className="tableList__Box">
            <thead>
              <tr>
                <th>{t('時間')}</th>
                <th>{t('訂單號')}</th>
                <th>{t('週期')}</th>
                <th>{t('金額')}</th>
                <th>{t('狀態')}</th>
              </tr>
            </thead>
            <tbody>
              {walletPage?.orders?.length > 0 &&
                walletPage?.orders.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <th>
                        {dayjs.unix(item.createtime).format("YYYY-MM-DD")}
                      </th>
                      <th>{item.number}</th>
                      <th>{item.cycle}</th>
                      <th>{item.amount}</th>
                      <th>{item.status === "1" ? t('進行中') : item.status === "0" ? t('結束') : (
                            <Button
                              onClick={() => onClickBtn(item)}
                              loading={btnLoadin}
                              size="small"
                              shape="rounded"
                              className="green-color white-color"
                            >
                              {t("取消復投")}
                            </Button>
                          ) }</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* <div className="WalletNet__bot">
          <div className="title">{t('資金明細')}</div>
          <div style={{overflowX: 'scroll'}}><table className="tableList__Box">
              <thead>
                <tr>
                  <th>{t('時間')}</th>
                  <th>{t('訂單號')}</th>
                  <th>{t('週期')}</th>
                  <th>{t('金額')}</th>
                  <th>{t('備註')}</th>
                </tr>
              </thead>
              <tbody>
                {funddetailPage?.length > 0 &&
                  funddetailPage?.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <th>{dayjs.unix(item.createtime).format('YYYY-MM-DD')}</th>
                        <th>{item.number}</th>
                        <th>{item.cycle}</th>
                        <th>{item.amount}</th>
                        <th>{item.memo}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </table></div>
          
        </div> */}
      </div>
    </>
  );
};
export default Index;
