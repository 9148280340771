import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub, modalComponents } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [ invitePage, setInvitePage] = useState<any>();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");


  const getInvitePage = async () => {
    try {
      const parmas = { account: email || address,
        token: token, };
      const res = await request({
        method: "POST",
        url: "/api/index/invitePage2",
        params: {lang: i18n.language},
        data: parmas,
      });
      setInvitePage(res.data);
      // setInvitePage(res.data)

      // setMainPage(res.data);
      // localSetItem('nodesellstatus', res.data.nodesellstatus);
      // setPowerList(res.data.powerproducts);
    } catch (error) {}
  };
  useEffect(() => {
    getInvitePage();
  }, []);

  return (
    <>
      <div className="InviteAllAward font14">
        <Header title={t('邀請獎勵')} />
        <div className="InviteAllAward__wrap">
          <div className="InviteAllAward__card">
            <div
              onClick={() => modalComponents(t("獎勵規則"), t("award"))}
              className="InviteAllAward__absolute"
            >
              {t('獎勵規則')}
            </div>
            <div className="mb4">{t('獎勵金額')}</div>
            <div className="font__bold font24 mb16">${invitePage?.directrewards}</div>
            <div className="flex__space__between text__center">
              <div>
                <div className="mb8">{t('一級邀請總數')}</div>
                <div className="font__bold">{invitePage?.directnum}</div>
              </div>
              <div>
                <div className="mb8">{t('個人投資總額')}</div>
                <div className="font__bold">${invitePage?.total}</div>
              </div>
              <div>
                <div className="mb8">{t('昨日獎勵')}</div>
                <div className="font__bold">${invitePage?.yesterdayrewards}</div>
              </div>
            </div>
          </div>
          <div style={{overflowX: 'scroll'}}>
            <table className="tableList__Box">
            <thead>
              <tr className="">
                <th>{t('用戶地址')}</th>
                <th>{t('當前等級')}</th>
                <th>{t('租賃金額')}</th>
                <th>{t('託管金額')}</th>
                <th>{t('節點金額')}</th>
                {/* <th>{t('昨日累計收益')}</th> */}
              </tr>
            </thead>
            <tbody>
              {invitePage?.tenteaminfo?.length > 0 &&
                invitePage?.tenteaminfo.map((item: any, index: any) => {
                  return (
                    <tr key={index} className="">
                      <th>{addressSub(item.address, 4, 4)}</th>
                      <th>{item.level}</th>
                      <th>{item.power}</th>
                      <th>{item.defiamount}</th>
                      <th>{item.powerincome}</th>
                      {/* <th>{item.defiincome}</th> */}
                    </tr>
                  );
                })}
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
