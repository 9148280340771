import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  useLocation,
  To,
  Link,
  useRoutes,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
// import WalletConnect from "../../components/Wallet";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import dayjs from "dayjs";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const goToPage = useNavitateLink();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [announcementList, setAnnouncementList] = useState<any>([]); // 所有公告
  const [announcement, setAnnouncement] = useState<any>([]); // 当前语言公告
  const [isDetail, setIsDetail] = useState<any>(false);

  // 获取公告
  const getAnnouncement = async () => {
    const res = await request({
      method: "GET",
      url: "/api/index/getnotices",
    });
    setAnnouncementList(res.data);
  };

  const changeAnnouncement = () => {
    if (announcementList?.length <= 0) {
      return;
    }

    const list = [...announcementList];

    const newData = list
      .map((item: any) => {
        const message =
          i18n.language === "tc" ? item.message.tc : item.message.en;
        if (message.text !== "") {
          return {
            title: message.title,
            text: message.text,
            createtime: message.createtime,
          };
        } else {
          return null; // 如果 message.text 为空，则返回 null
        }
      })
      .filter((item: any) => item !== null);
    setAnnouncement(newData);
  };

  useEffect(() => {
    getAnnouncement();
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", changeAnnouncement);
    changeAnnouncement();
  }, [announcementList]);

  useEffect(() => {
    const nowUrl = location.pathname;
    setIsDetail(nowUrl === "/announcement/detail");
  }, [location]);

  return (
    <>
      <div className="Announcement">
        <Header title={t("公告中心")} />
        {!isDetail && (
          <div className="Announcement__list">
            {announcement?.length > 0 &&
              announcement.map((item: any, index: any) => {
                return (
                  <div
                    onClick={() => {
                      goToPage("/announcement/detail", { state: item });
                    }}
                    key={index}
                    className="Announcement__box"
                  >
                    <div
                      key={index}
                      className="Announcement__title"
                    >
                      {item.title}
                    </div>
                    <span className="Announcement__time">
                      {dayjs.unix(item.createtime).format("YYYY-MM-DD")}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
        <Outlet></Outlet>
      </div>
    </>
  );
};
export default Index;
