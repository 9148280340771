import { createBrowserRouter, Navigate } from "react-router-dom";
import {
  Home,
  InviteFriend,
  OrderDetail,
  AllAward,
  InviteAllAward,
  TeamAward,
  Wallet,
  CloudComputed,
  NodeBuy,
  NodeBuyIncome,
  Defi,
  DefiIncome,
  DefiPay,
  ManageMoney,
  ManageDetail,
  Income,
  Lease,
  Deposit,
  Withdraw,
  DepositAddress,
  OnlineRecharge,
  OnlineWithdraw,
  TeamDetail,
  AboutUs,
  ActionRecord,
  Lottery,
  Magnetic,
  Announcement,
  AnnouncementDetail,
  Login,
  ChangePassword,
  ForgotPassword,
  Registration,
  BindEmail
} from "pages";
import AuthRoute from "components/AuthRoute";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/announcement",
    element: <AuthRoute><Announcement /></AuthRoute>,
    children: [
      {
        path: "detail",
        element: <AuthRoute><AnnouncementDetail /></AuthRoute>,
      },
    ],
  },
  {
    path: "/inviteFriend",
    element: <AuthRoute><InviteFriend /></AuthRoute>,
  },
  {
    path: "/orderDetail",
    element: <AuthRoute><OrderDetail /></AuthRoute>,
  },
  {
    path: "/allAward",
    element: <AuthRoute><AllAward /></AuthRoute>,
  },
  {
    path: "/inviteAllAward",
    element: <AuthRoute><InviteAllAward /></AuthRoute>,
  },
  {
    path: "/teamAward",
    element: <AuthRoute><TeamAward /></AuthRoute>,
  },
  {
    path: "/wallet",
    element: <AuthRoute><Wallet /></AuthRoute>,
  },
  {
    path: "/cloudComputed",
    element: <AuthRoute><CloudComputed /></AuthRoute>,
    children: [
      {
        path: "income",
        element: <AuthRoute><Income /></AuthRoute>,
      },
      {
        path: "lease/:id",
        element: <AuthRoute><Lease /></AuthRoute>,
      },
    ],
  },
  {
    path: "/nodeBuy",
    element: <AuthRoute><NodeBuy /></AuthRoute>,
  },
  {
    path: "/nodeBuyIncome",
    element: <AuthRoute><NodeBuyIncome /></AuthRoute>,
  },
  {
    path: "/defi",
    element: <AuthRoute><Defi /></AuthRoute>,
  },
  {
    path: "/defiIncome",
    element: <AuthRoute><DefiIncome /></AuthRoute>,
  },
  {
    path: "/defiPay/:id",
    element: <AuthRoute><DefiPay /></AuthRoute>,
  },
  {
    path: "/manageMoney",
    element: <AuthRoute><ManageMoney /></AuthRoute>,
  },
  {
    path: "/manageDetail",
    element: <AuthRoute><ManageDetail /></AuthRoute>,
  },
  {
    path: "/deposit",
    element: <AuthRoute><Deposit /></AuthRoute>,
  },
  {
    path: "/withdraw",
    element: <AuthRoute><Withdraw /></AuthRoute>,
  },
  {
    path: "/depositAddress",
    element: <AuthRoute><DepositAddress /></AuthRoute>,
  },
  {
    path: "/onlineRecharge",
    element: <AuthRoute><OnlineRecharge /></AuthRoute>,
  },
  {
    path: "/onlineWithdraw",
    element: <AuthRoute><OnlineWithdraw /></AuthRoute>,
  },
  {
    path: "/teamDetail",
    element: <AuthRoute><TeamDetail /></AuthRoute>,
  },
  {
    path: "/lottery",
    element: <AuthRoute><Lottery /></AuthRoute>,
  },
  {
    path: "/magnetic",
    element: <AuthRoute><Magnetic /></AuthRoute>,
  },
  {
    path: "/aboutUs",
    element: <AuthRoute><AboutUs /></AuthRoute>,
  },
  {
    path: "/actionRecord/:id",
    element: <AuthRoute><ActionRecord /></AuthRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/bindEmail",
    element: <BindEmail />,
  },
  // {
  //   path: "/announcementDetail",
  //   element: <AnnouncementDetail />,
  // },
]);

export default router;
