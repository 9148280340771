import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import {
  Form,
  Space,
  TabBar,
  Button,
  Input,
  Toast,
  Selector,
  List,
  Image,
  Modal,
} from "antd-mobile";
import { getContract, simpleRpcProvider } from "utils/getContract";
import { config } from "config";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import { addressSub } from "utils/tools";
import { trim } from "utils/tools";
import {
  useNavigate,
  Outlet,
  Route,
  To,
  Link,
  useLocation,
} from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { ethers, BigNumber, utils } from "ethers";
import copy from "copy-to-clipboard";
import { text } from "stream/consumers";
import { injected } from "utils/web3React";
import withRouter from "components/withRouter";
import cloudBanner from "assets/images/cloudBanner.png";
import rightWihite from "assets/images/rightWihite.png";
import monitorImg from "assets/images/monitorImg.png";
import BTCIcon from "assets/images/BTCIcon.png";
import WalletConnect from "components/Wallet";
import { useTranslation } from "react-i18next";
import { throttle } from 'lodash';
import "./index.scss";
import Header from "components/Header";
import request from "utils/request";
import useNavitateLink from "utils/useNavitateLink";
const Index: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { active, account, activate, deactivate } = useWeb3React();
  const [isIcome, setIsIcome] = useState<any>(false);
  const [nodePage, setNodePage] = useState<any>();
  const goToPage = useNavitateLink();
  let location = useLocation();
  // @ts-ignore
  const provider = window["ethereum"] || (window.web3 && window.web3.currentProvider);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const address = localStorage.getItem("address");

  const logout = useCallback(() => {
    deactivate();
  }, []);
  const login = useCallback(() => {
    activate(injected, async (error) => {
      if (error instanceof UnsupportedChainIdError) {
        let switchId = `0x${parseInt(
          String(config.REACT_APP_CHAINID),
          10
        ).toString(16)}`;
        console.log(switchId);
        try {
          let data = [{ chainId: switchId }];
          await provider.request({
            method: "wallet_switchEthereumChain",
            params: data,
          });
          login();
          // NotiSuccess(t("Switch the chain"), `${t("Has switched to")} ${coinInfo.name}`)
          console.log("Switch the chain");
        } catch (e: any) {
          console.log(e);
        }
      } else {
        if (error instanceof NoEthereumProviderError) {
          // NotiError("Provider Error", "No provider was found")
          console.log("Provider Error");
        }
      }
    });
  }, []);

   // /api/index/nodePage
  const getNodePage =async () => {
    try {
      const parmas = { account: email || address,
        token: token,};
      const res = await request({
        method: 'POST',
        url: '/api/index/nodePage',
        params: {lang: i18n.language},
        data: parmas
      })
      setNodePage(res.data);
    } catch (error) {
      
    }
  }
  const onClickNodeBuy =async (v: any) => {
    try {
      if (v.status === '1' ) {
        const parmas = { account: email || address,
        token: token, powerid: v.id};
        const res: any = await request({
          method: 'POST',
          url: '/api/users/buypower2',
          params: {lang: i18n.language},
          data: parmas
        })
        if (res.code === 1) {
          Toast.show(res.msg)
          goToPage("/wallet");
        }
        if (res.code === 0) {
          Toast.show(res.msg)
        }
      }
    } catch (error) {
      
    }
  }

  const ThrottledOnClickNodeBuy  = throttle(onClickNodeBuy, 3000, { leading: true, trailing: false });


  useEffect(() => {
    getNodePage();
  }, [i18n.language]);

  return (
    <>
      <div className="NodeBuy">
        <>
          <Header title={t('節點搶購')} />

          <div className="NodeBuy__greenBox">
            <div className="NodeBuy__imgBox flex__space__between">
              <div className="imgBox__left">
                <div>{t('一站式便捷算力租賃')}</div>
                <div className="imgBox__left__bold">{t('第一批節點購買，限購30天')}</div>
                <div className="imgBox__left__btn">{t('立即參與')}</div>
              </div>
              <Image src={cloudBanner} width={106} height={114} />
            </div>
            <div className="flex__space__between">
            <div className="left__box">
              <div className="bgGreenAsh__box flex__direction">
                <div className="paddingt12 text__center mb4">{t('資產總計')}</div>
                <div className="Defi__num text__center font18">
                  ${nodePage?.amount}
                </div>
                <div
                  onClick={() =>
                    goToPage("/nodeBuyIncome", {
                      state: "1",
                    })
                  }
                  className="flex__space__center text__center"
                >
                  <span>{t('訂單詳情')}</span>
                  <Image
                    className="imgClass"
                    src={rightWihite}
                    width={6}
                    height={6}
                  />
                </div>
              </div>
            </div>
            <div className="left__box">
              <div className="bgGreenAsh__box flex__direction">
                <div className="paddingt12 text__center mb4">{t('累計收益')}</div>
                <div className="Defi__num text__center font18">
                  +${nodePage?.income}
                </div>
                <div
                  onClick={() =>
                    goToPage("/nodeBuyIncome", {
                      state: "0",
                    })
                  }
                  className="flex__space__center text__center"
                >
                  <span>{t('收益明細')}</span>
                  <Image
                    className="imgClass"
                    src={rightWihite}
                    width={6}
                    height={6}
                  />
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="NodeBuy__bottom">
            <div className="">
              <div className="bot__title">{t('節點搶購推薦')}</div>
              <div className="font__bold font16">{t('搶購時間')}：{nodePage?.leftday}</div>
            </div>
            {
              nodePage?.nodeproducts?.length > 0 && nodePage?.nodeproducts.map((item: any, index: any) => {
                return <div key={index} className="card__box">
                <div className="card__title flex__space__between">
                  <div className="font__bold font20">{item.name}</div>
                  <div>
                    {/*<span className="font__bold">{t('收益三倍出局')}</span>*/}
                    <span className="font__bold">{t('每日返利，收益三倍出局')}</span>
                  </div>
                </div>
                <div className="card__content">
                  <div className="card__BTC font__bold">
                    <Image src={BTCIcon} width={40} height={40} />
                    <span>BTC</span>
                  </div>
                  <div className="content__list flex__space__between mt12">
                    <div className="title__font">
                      {/* <div className="title__font">算力售價</div>
                      <div className="font__bold">{item.powerprice}USDT/T</div> */}
                      <div className="">{t('買一送半')}</div>
                      <div className="">{t('限購total',{ total: item.quota})}</div>
                      <div className="">{t('贈送等级', {day: item.vipdays, lev: item.viplevel})}</div>
                      <div className="">{t('終身享受每週一次', {num: item.fhrate})}</div>
                    </div>
                    <div className="text__right">
                      {/* <div className="title__font">到期礦機折損</div>
                      <div className="font__bold"> {item.damagerate}</div> */}
                      <div className="title__font">{t('預估日化收益率')}</div>
                      <div className="font__bold green2-color">{item.dayrate}</div>
                    </div>
                  </div>
                  <div className="content__btn flex__space__between font__bold">
                    <div>
                      {t('售價')}: <span className="yellow-color">{item.price}USDT</span>
                    </div>
                    <div
                      onClick={() => ThrottledOnClickNodeBuy (item)}
                      className="nowBtn"
                    >
                      {item.status === '1' ? t('立即購買') : t('售罄')}
                    </div>
                  </div>
                </div>
              </div>
              })
            }
          </div>
        </>
      </div>
    </>
  );
};
export default Index;
